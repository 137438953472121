<template>
  <v-select
    v-bind="$attrs"
    v-on="$listeners"
    v-model="selected"
    :menu-props="{ offsetY: true, overflowY: true, closeOnContentClick: true }"
    :items="items"
    :label="$t('currency.code')"
    item-text="text"
    item-icon="icon"
    item-value="value"
  >
    <template v-slot:selection="{ item }">
      {{ item.text }}
    </template>

    <template slot="item" slot-scope="data">
      <span class="font-weight-medium mr-1">{{ data.item.symbol }}</span>
      {{ data.item.text }}
    </template>
  </v-select>
</template>

<script>
  import currenyCode from "@/base/data/currency-code";

  export default {
    inheritAttrs: false,
    name: "CurrencyPicker",
    props: {
      value: [String, Array]
    },
    data: () => ({
      items: currenyCode,
      selected: null
    }),
    watch: {
      value: {
        handler(v) {
          this.selected = v;
        },
        immediate: true
      }
    }
  };
</script>

<style scoped></style>
